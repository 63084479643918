import React from "react";

const iframe = () => (
  <form
    id="collectionForm"
    method="POST"
    action="https://secure-test.worldpay.com/shopper/3ds/ddc.html">
    <input
      id="worldpayIframeBin"
      name="Bin"
      value=""
    />
    <input
      id="worldpayIframeJWT"
      name="JWT"
      value=""
    />
  </form>
);

export default iframe;
